
import { defineComponent } from 'vue';
import { WorkController, ProjectController, TimeEntryController, SessionController, ClientController } from '@/controller/';
import { List, ListItem, IconButton, UiTimeInput, Button, SelectSingle } from '@/ui/index';
import { User, Project, Employee, Work, TimeEntry } from '@/model';
import store from '@/store/index';

export default defineComponent({
    name: "EditTimeEntry",
    components: { List, ListItem, IconButton, UiTimeInput, Button, SelectSingle },
    created(){
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.handleGetTimeEntry(this.$route.params.id);
        }
        
        this.getSessionUser()
    },
    data(){
        return{
            store,
            projects: [] as Array<Project>,
            clients: [] as Array<any>,
            works: [] as Array<Work>,
            timeEntry: {} as TimeEntry,
            form:{
                projectId: 0,
                customerId: 0,
                workId: 0,
                workstationId: 0,
                employeeId: 0,
                hours: 0,
                minutes: 0,
                time: 0
            },
            searchInput:'',
            activeTab: 0,
            sessionUser: {} as User,
            sessionEmployee: {} as Employee,
            timeChanged: false
        }
    },
    computed:{
        filteredProjectList(){
            if(this.projects){
                let projectList : any = [...this.projects]

                if(this.searchInput != ""){
                    projectList = projectList.filter((item:any)=>{
                        if(item.title.toLowerCase().includes(this.searchInput.toLowerCase())){
                            return item
                        }
                    })
                }
                return projectList
            }
            return []
        },
        filteredWorkList(){
            if (this.works && (this.works as any).items) {
                
                let workList : any = (this.works as any).items

                if(this.searchInput != ""){
                    workList = workList.filter((item:any)=>{
                        if(item.title.toLowerCase().includes(this.searchInput.toLowerCase())){
                            return item
                        }
                    })
                }
                return workList
            }
            return []
        },
        selectedWork() : Work | undefined {
            const works = (this.works && (this.works as any).items) ? (this.works as any).items : undefined
            if (works) {
                return works.find((w:Work) => w.uid == this.form.workId)
            } else {
                return undefined
            }
        },
        selectedProject(): Project | undefined {
            const projects = this.projects ? this.projects : null
            if (projects) {
                return projects.find((p:Project) => p.uid == this.form.projectId)
            } else {
                return undefined
            }
        }
    },
    methods: {
        handleCancel() {
            this.$router.go(-1)
        },
        handleOnChangeSearchInput(input : string) {
            this.searchInput = input
        },
        handleSelectProject(item:any) {
            this.form.projectId = item.uid
            this.form.customerId = item.customer.uid
        },
        handleSelectWork(item:any) {
            this.form.workId = item.uid
        },
        handleSubmitPrevent() {
            this.handleUpdateTimeEntry()
        },
        handleTimeChanged(property: string, value: any) {
            //console.log('handleTimeChanged', value)
            this.form.time = value?.sec
            this.timeChanged = true
        },
        handleSave() {
            this.handleUpdateTimeEntry()
        },
        handleWorkChange(item:any, value:number, text:string) {
            //console.log('handleWorkChange', item, value, text);
            this.form.workId = value
        },  
        handleProjectChange(item:any, value:number, text:string) {
            //console.log('handleProjectChange', item, value, text);
            this.form.projectId = value
        },
        setFormValues() {
            //console.log('this.timeEntry', this.timeEntry);
            this.form.projectId = this.timeEntry.project?.uid
            this.form.customerId = this.timeEntry.customer?.uid
            this.form.workId = this.timeEntry.work?.uid
            this.form.employeeId = this.timeEntry.employee.uid
            if (this.timeEntry.editTime != 0) {
                this.form.time = this.timeEntry.editTime
            } else {
                this.form.time = this.timeEntry.getTimeTracked()
                /* console.log('this.timeEntry.startStops', this.timeEntry.startstops)
                for(const key in this.timeEntry.startstops) {
                    const entry = this.timeEntry.startstops[key]
                    console.log('entry.start / entry.stop / now ', entry.start, entry.stop, Math.floor(Date.now() / 1000))
                    this.form.time += entry.stop ? (entry.stop - entry.start) / 1000 : (Math.floor(Date.now() / 1000) - entry.start) / 1000
                } */
            }
        },
        async getSessionUser() {
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
            this.form.employeeId = this.sessionEmployee ? this.sessionEmployee.getUid() : 0
            
            this.handleGetProjects()
            this.handleGetClients()
            this.handleGetWorks()
        },        
        async handleGetTimeEntry(id: any) {
            //TODO: implement getTimeEntry in controller and backend
            this.timeEntry = await TimeEntryController.getTimeEntry(id)
            this.setFormValues();
        },
        async handleGetProjects() {
            this.projects = (await ProjectController.fetchProjects()).items
        },
        async handleGetClients() {
            this.clients = await ClientController.fetchClients()
        },
        async handleGetWorks() {
            this.works = await WorkController.fetchWorks()
            console.log('this.works', this.works)
        },
        async handleUpdateTimeEntry() {
                //send ms to server
            const result = await TimeEntryController.updateTimeEntry(this.timeEntry.uid,  this.form.time, this.timeChanged, this.timeEntry.tstamp, this.form.employeeId, this.form.workId, this.form.projectId, this.form.customerId, this.form.workstationId)
            //console.log('handleUpdateTimeEntry', result)
            this.$router.go(-1) 
        },
    },
})
